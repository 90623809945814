// material-ui
import { styled } from '@mui/material/styles';
import { isPoetProject } from 'utils';

const isPoet = isPoetProject();

// ==============================|| AUTHENTICATION 1 WRAPPER ||============================== //

const AuthWrapper1 = styled('div')(({ theme }) => ({
    minHeight: '100vh',
    ...(!isPoet && { backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary.light })
}));

export default AuthWrapper1;
