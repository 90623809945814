import { useNavigate, useParams } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH } from 'config';
import { GuardProps } from 'types';
import { useEffect } from 'react';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children, ...props }: GuardProps) => {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const params = useParams();
    const tenantName = localStorage.getItem('tenantPath') || `/${params.tenantName}/`;

    useEffect(() => {
        if (isLoggedIn) {
            navigate(DASHBOARD_PATH(tenantName || ''), { replace: true });
        }
    }, [isLoggedIn, navigate, tenantName]);

    return children;
};

export default GuestGuard;
