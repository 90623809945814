import React, { createContext, useEffect, useReducer } from 'react';

// third-party
import { CognitoUser, CognitoUserPool, CognitoUserSession, CognitoUserAttribute, AuthenticationDetails } from 'amazon-cognito-identity-js';

// reducer - state management
import { LOGIN, LOGOUT, SAVE_FIREBASE_USER } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import { AWS_API } from 'config';
import { AWSCognitoContextType, InitialLoginContextProps } from 'types/auth';
import { isTokenExpired } from 'utils';
import { useLocation } from 'react-router-dom';

// constant
const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

export const userPool = new CognitoUserPool({
    UserPoolId: AWS_API.poolId || '',
    ClientId: AWS_API.appClientId || ''
});

const setSession = (serviceToken?: string | null) => {
    if (serviceToken) localStorage.setItem('serviceToken', serviceToken);
    else localStorage.removeItem('serviceToken');
};

// ==============================|| AWS Cognito CONTEXT & PROVIDER ||============================== //
const AWSCognitoContext = createContext<AWSCognitoContextType | null>(null);

export const AWSCognitoProvider = ({ children }: { children: React.ReactElement }) => {
    const location = useLocation();
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                const backendToken = window.localStorage.getItem('backend_jwt');
                if (serviceToken && !isTokenExpired(backendToken)) {
                    setSession(serviceToken);
                    loginWithBackendData();
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: {
                                name: 'Betty'
                            }
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    useEffect(() => {
        const backendToken = window.localStorage.getItem('backend_jwt');
        if (isTokenExpired(backendToken))
            dispatch({
                type: LOGOUT
            });
    }, [location]);

    const login = async (email: string, password: string) =>
        new Promise((res) => {
            const usr = new CognitoUser({
                Username: email,
                Pool: userPool
            });

            const authData = new AuthenticationDetails({
                Username: email,
                Password: password
            });

            usr.authenticateUser(authData, {
                onSuccess: (session: CognitoUserSession) => {
                    setSession(session.getAccessToken().getJwtToken());

                    usr.getUserAttributes((err, attributes) => {
                        if (err || !attributes) {
                            console.log(err);
                            return;
                        }

                        // eslint-disable-next-line no-plusplus
                        for (let i = 0; i < attributes.length; i++) {
                            if (attributes[i].getName() === 'given_name') {
                                console.log(`Nombre del usuario actual: ${attributes[i].getValue()}`);
                                break;
                            }
                        }
                    });

                    res(false);

                    dispatch({
                        type: SAVE_FIREBASE_USER,
                        payload: {
                            isLoggedIn: false,
                            user: {
                                id: session.getIdToken().payload.sub,
                                email: authData.getUsername()
                                // name: authData.getValidationData() || 'John Doe'
                            }
                        }
                    });
                },
                onFailure: (_err: any) => {
                    res({ error: _err });
                },
                newPasswordRequired: (_userAttributes: unknown, _requiredAttribute: unknown) => {
                    // // User was signed up by an admin and must provide new
                    // // password and required attributes, if any, to complete
                    // // authentication.
                    // // the api doesn't accept this field back
                    // delete userAttributes.email_verified;
                    // // unsure about this field, but I don't send this back
                    // delete userAttributes.phone_number_verified;
                    // // Get these details and call
                    // usr.completeNewPasswordChallenge(password, userAttributes, requiredAttributes);
                }
            });
        });

    const loginWithBackendData = () => {
        dispatch({
            type: LOGIN
        });
    };

    const register = (email: string, password: string, firstName: string, lastName: string) =>
        new Promise((success, rej) => {
            userPool.signUp(
                email,
                password,
                [
                    new CognitoUserAttribute({ Name: 'email', Value: email }),
                    new CognitoUserAttribute({ Name: 'name', Value: firstName }),
                    new CognitoUserAttribute({ Name: 'family_name', Value: lastName })
                ],
                [],
                async (err: unknown, result: unknown) => {
                    if (err) {
                        rej(err);
                        return;
                    }
                    success(result);
                }
            );
        });

    const logout = () => {
        const loggedInUser = userPool.getCurrentUser();
        if (loggedInUser) {
            setSession(null);
            localStorage.removeItem('backend_jwt');
            dispatch({ type: LOGOUT });
            loggedInUser.signOut();
        }
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <AWSCognitoContext.Provider value={{ ...state, login, logout, register, loginWithBackendData }}>
            {children}
        </AWSCognitoContext.Provider>
    );
};

export default AWSCognitoContext;
